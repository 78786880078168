@import "../../Styles/theme.scss";

.introHeader {
  justify-content: center;
  text-align: center;
  display: flex;
  font-weight: $mediumWeight;
  font-size: $heading2;
  color: $neutral7;
  margin-top: 0;
  margin-top: 45px;
  margin-bottom: 24px;
}

.carouselWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.carouselContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  width: 100%;
  max-width: 600px;
  margin: auto;
  padding: 0 20px;
  box-sizing: border-box;
}

.contentContainer {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  justify-items: center;
}

.assetContainer {
  width: 100%;
  display: flex;
  justify-content: center;
}

.fixedContainer {
  width: 500px;
  height: 333px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
  border-radius: 8px;
  background-color: $neutral2;
}

.image {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.video {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.text {
  text-align: center;
  font-size: 1.2rem;
  padding: 20px;
  color: $neutral7;
}

.carouselButton {
  position: absolute;
  width: 45px;
  top: 50%;
  transform: translateY(-50%);
  background-color: $primary3;
  border-radius: 2px;
  color: white;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  z-index: 2;
  padding: 10px;
}

.carouselButton:hover {
  background-color: rgba(0, 0, 0, 0.7);
}

.carouselButton:first-of-type {
  left: -10px;
}

.carouselButton:last-of-type {
  right: -10px;
}

.dotsContainer {
  display: flex;
  justify-content: center;
  margin-top: 15px;
}

.dot {
  width: 10px;
  height: 10px;
  margin: 0 5px;
  border-radius: 50%;
  background-color: $unexpected2;
  cursor: pointer;
}

.dot.active {
  background-color: $primary3;
}


@media (max-width: 768px) {
  .fixedContainer {
    width: 100%;
    max-width: 90%;
    height: auto;
    aspect-ratio: 3 / 2;
  }

  .carouselContainer {
    max-width: 100%;
    padding: 0 15px;
  }

  .carouselButton {
    font-size: 1.2rem;
  }

  .introHeader {
    font-size: $heading3;
  }
}

@media (max-width: 480px) {
  .introHeader {
    margin-top: 0px;
  }
  .fixedContainer {
    max-width: 100%;
    max-height: 200px; /* Further limit height on very small screens */
    aspect-ratio: 3 / 2; /* Maintain aspect ratio */
  }

  .text {
    font-size: 1rem;
  }
  .dotsContainer {
    margin-top: 10px;
  }

  .dot {
    width: 8px;
    height: 8px;
    margin: 0 3px;
  }

}
