@import "../../../Styles/theme.scss";

.content {
  width: 100%;
  padding: 24px;
  display: flex;
  box-sizing: border-box;
  flex: 1;
  flex-direction: column;
  justify-content: space-around;
}

.buttons {
  display: flex;
  gap: 10px;
  margin-top: 100px;
}

.card_content {
  min-width: 500px;
  display: flex;
  flex-direction: column;
}

@media (max-width: $mobile-break-point) {
  .buttons {
    width: 100%;
    margin-bottom: 0px;
    margin-top: 32px;
    flex-direction: column-reverse;
  }

  .card_content {
    min-width: unset;
    padding: 24px;
  }
}